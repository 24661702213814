import { captureException } from "@sentry/vue"
import {
  AuthFlowType,
  B2BOAuthProviders,
  B2BProducts,
  StytchB2BUIClient,
  type Callbacks,
  type StytchB2BUIConfig,
} from "@stytch/vanilla-js/b2b"
import { apolloClient } from "~/config/apollo"
import { stytchPublicToken } from "~/config/environment"
import getExpiringToken from "~/graphql/queries/getExpiringToken"
import { authorize, createOrUpdateUser, endUserSession } from "~/lib/authRequests"
import { useUserInfoStore } from "~/stores/userInfo"
import CypressMockStytchClient from "../cypress/support/mock-stytch"
import { cleanTemporaryLocalStorageItems } from "./componentUtils"
import { showException } from "./notify"

class CannotAuthWithoutStytchToken extends Error {
  constructor() {
    super("Cannot auth user without Stytch token")
    this.name = "CannotAuthWithoutStytchToken"
  }
}

function getCodeVerifierFromStytchState(publicToken: string) {
  // Undocumented way of getting the Stytch PKCE verifier from local storage --
  // if this is broken, Stytch likely changed in under the hood on us!
  const pkceStorageKey = `stytch_sdk_state_${publicToken}::PKCE_VERIFIER:oauth`
  const pkceStorageValue = localStorage.getItem(pkceStorageKey)

  if (!pkceStorageValue || pkceStorageValue.length === 0) throw new Error("No PKCE key")

  return JSON.parse(pkceStorageValue).code_verifier
}

function createStytchClient(publicToken: string) {
  // @ts-expect-error - we'll be removing Cypress soon anyway, will solve the type error then
  if (window.Cypress) {
    return new CypressMockStytchClient() as unknown as StytchB2BUIClient
  }

  return new StytchB2BUIClient(publicToken)
}

const stytchClient = () => {
  if (!stytchPublicToken) throw new CannotAuthWithoutStytchToken()

  return createStytchClient(stytchPublicToken)
}

const redirectFor = (authenticateOrAuthorize: string, appType: string, returnUrl?: string) => {
  return `${
    window.location.origin
  }/${authenticateOrAuthorize}?appType=${appType}&returnUrl=${encodeURIComponent(returnUrl || "")}`
}

const stytchOauthProvider = (provider: string) => {
  switch (provider) {
    case "google": {
      return stytchClient().oauth.google
    }
    case "microsoft365": {
      return stytchClient().oauth.microsoft
    }
    default: {
      throw new Error(`Unknown provider: ${provider}`)
    }
  }
}

export const mountLogin = async (
  elementId: string,
  styles: object,
  callbacks: Callbacks,
  appType: string,
  returnUrl?: string,
): Promise<void> => {
  const authConfig: StytchB2BUIConfig = {
    products: [B2BProducts.oauth],
    oauthOptions: {
      providers: [B2BOAuthProviders.Google, B2BOAuthProviders.Microsoft],
      discoveryRedirectURL: redirectFor("authenticate", appType, returnUrl),
      // force the user to explicitly select the account they want to use
      providerParams: {
        prompt: "select_account",
      },
    },
    authFlowType: AuthFlowType.Discovery,
    sessionOptions: {
      // Typescript type requires this, which isn't actually correct. It's ignored anyway; session
      // is issued on the backend. 5 mins is the minimum
      sessionDurationMinutes: 5,
    },
  }

  // magic links don't work through the Word add-in (there's no way to click the emailed link to authorize
  // within Word's built-in browser for the add-in)
  if (appType !== "office-addin") {
    authConfig.products = [...authConfig.products, B2BProducts.emailMagicLinks]
    authConfig.emailMagicLinksOptions = {
      discoveryRedirectURL: redirectFor("authenticate", appType, returnUrl),
    }
  }

  stytchClient().mount({
    elementId,
    styles,
    callbacks,
    config: authConfig,
  })
}

export const finishLoginOrRegister = async (
  token: string,
  tokenType: string,
  appType: string,
): Promise<void> => {
  if (!stytchPublicToken) throw new CannotAuthWithoutStytchToken()

  let pkceVerifier = undefined
  // we only use PKCE for Oauth; it doesn't work great for email because it prevents the user from
  // opening the email link with a different browser or device than the one they started the
  // request on
  if (tokenType === "discovery_oauth") {
    pkceVerifier = getCodeVerifierFromStytchState(stytchPublicToken)
  }

  await createOrUpdateUser({
    token,
    tokenType,
    pkceVerifier,
    appType,
  })

  // ensure the logged in user doesn't start with any
  // dangling temporary local storage items
  // also to consider moving authenticate from /middleware to /pages
  // since middleware functions are running on every page load
  cleanTemporaryLocalStorageItems()
}

export const startAuthorizing = async (
  provider: string,
  appType: string,
  returnUrl?: string,
  newScopes: string[] = [],
): Promise<void> => {
  const userInfoStore = useUserInfoStore()
  const oauthProvider = stytchOauthProvider(provider)
  const stytchOrganizationId = userInfoStore.data!.stytch_organization_id

  let existingScopes: string[]

  if (provider === "google") {
    existingScopes = userInfoStore.data!.permissions!.google.granted_scopes
  } else if (provider === "microsoft365") {
    // MS issues a refresh token for all previous scopes, plus the new ones, so we don't have to
    // add them. Plus, if we DO add those scopes to the request, then Microsoft shows all of the
    // scopes on the consent screen, which is confusing to the user.
    existingScopes = []
  } else {
    throw new Error(`Unknown provider: ${provider}`)
  }

  // De-pupe the scopes
  const scopesToRequest = [...new Set([...existingScopes, ...newScopes])] as string[]

  const authConfig = {
    login_redirect_url: redirectFor("authorize", appType, returnUrl),
    signup_redirect_url: redirectFor("authorize", appType, returnUrl),
  }

  oauthProvider.start({
    ...authConfig,
    custom_scopes: scopesToRequest,
    organization_id: stytchOrganizationId,
    // force the user to explicitly select the account they want to use
    provider_params: {
      login_hint: userInfoStore.data!.email,
    },
  })
}

export const finishAuthorizing = async (token: string, appType: string): Promise<void> => {
  if (!stytchPublicToken) throw new CannotAuthWithoutStytchToken()

  const pkceVerifier = getCodeVerifierFromStytchState(stytchPublicToken)

  await authorize({ token, pkceVerifier, appType })
}

export const logout = async (): Promise<void> => {
  // use the mock client logout for Cypress
  // @ts-expect-error - we'll be removing Cypress soon anyway, will solve the type error then
  if (window.Cypress) {
    await stytchClient().session.revoke()
    return
  }
  cleanTemporaryLocalStorageItems()

  try {
    await endUserSession()
  } catch (error) {
    captureException(error)
  }
}

export const getExpiringAuthToken = async (): Promise<string | undefined> => {
  try {
    const { data } = await apolloClient.query({
      query: getExpiringToken,
      fetchPolicy: "no-cache",
    })
    return data.expiringToken
  } catch (error) {
    showException("There was an error communicating with the server, please try again.", error)
  }
}
