import { captureMessage } from "@sentry/vue"
import { AxiosError } from "axios"
import { minBy } from "es-toolkit"
import { isEmpty } from "es-toolkit/compat"
import type { DocumentNode } from "graphql/language/ast"
import { type Component } from "vue"
import { unifiedWorkspaceId } from "~/config/environment"
import { downloadBlob } from "~/lib/blob-utils"
import { logUsage } from "~/lib/logUsage"
import { showException } from "~/lib/notify"
import { useUserInfoStore } from "~/stores/userInfo"
import BoxIcon from "~icons/recital/box"
import GoogleDriveIcon from "~icons/recital/google-drive"
import EmailIcon from "~icons/recital/mail"
import OneDriveIcon from "~icons/recital/one_drive"
import SharepointIcon from "~icons/recital/sharepoint"
import { getExpiringAuthToken } from "./auth"
import { bestNameFromFileLocations } from "./fileUtils"

export const isFirefox = (): boolean => {
  return navigator.userAgent.toLowerCase().includes("firefox")
}

export function getFileExtension(filePath: string): string {
  const lastDotIndex = filePath.lastIndexOf(".")
  if (lastDotIndex === -1) return "" // or return null, or throw an error, depending on your use case
  return `.${filePath.slice(lastDotIndex + 1)}`
}

export function getFileName(filePath: string): string {
  return filePath.slice(filePath.lastIndexOf("/") + 1, filePath.lastIndexOf("."))
}

const firstFileLocation = (fileLocations: FileLocation[] | undefined): FileLocation => {
  if (!fileLocations?.length) throw new Error(`There are no file locations to find the first`)

  const earliest = minBy(fileLocations, (fl) =>
    fl.receivedAt instanceof Date ? fl.receivedAt.getTime() : fl.receivedAt,
  )

  if (!earliest) throw new Error(`Somehow couldn't find file location for non-empty list`)

  return earliest
}

// Once we can upgrade to Apollo Client v3 (depends on Vue 3 & vue-apollo v4),
// these can all be changed into local-only Apollo fields that can be used
// directly from the Apollo objects themselves:
// https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies

export const earliestFileLocation = (fileRecord: FileRecord): FileLocation => {
  return firstFileLocation(fileRecord.fileLocations)
}

const earliestTime = (fr: FileRecord): number => {
  const receivedAt = earliestFileLocation(fr).receivedAt
  return receivedAt instanceof Date ? receivedAt.getTime() : new Date(receivedAt).getTime()
}

export const fileRecordsOrderedByReceivedDate = (fileRecords: FileRecord[]): FileRecord[] => {
  if (!fileRecords) return []

  return [...fileRecords].sort(
    (a, b) => earliestTime(b as FileRecord) - earliestTime(a as FileRecord),
  ) as FileRecord[]
}

export const bestNameForFileRecord = (fileRecord: FileRecord): string => {
  if (!fileRecord) throw new Error("File Content is required")
  if (fileRecord.loading) return fileRecord.uploadingFileName

  const { fileLocations } = fileRecord
  return bestNameFromFileLocations<FileLocation>(fileLocations)
}

export const getFileType = (fileName: string): string => {
  if (!fileName) return "file"

  const fileExtension = getFileExtension(fileName)
  return [".docx", ".doc", ".dotx"].includes(fileExtension.toLowerCase()) ? "word" : "pdf"
}

function nameOrEmail(contact: Contact) {
  return contact.name?.length ? contact.name : contact.email
}

interface FileLocationOriginArg {
  originatesFromThisUser?: boolean
  storedInService: string
  parentName?: string
  email?: {
    to?: {
      id: string
      name: string
      email: string
    }[]
    from?: {
      id: string
      name: string
      email: string
    }
  }
}

export const fileLocationOriginDescription = (
  fileLocation: FileLocationOriginArg,
): FileLocationOrigin => {
  if (
    fileLocation.originatesFromThisUser === undefined ||
    fileLocation.originatesFromThisUser === null
  ) {
    throw new Error("Error: missing originatesFromThisUser")
  }

  if (fileLocation.storedInService === "email") {
    const { email } = fileLocation
    if (!email) throw new Error("Error: missing email")
    if (!Array.isArray(email.to)) throw new Error("Error: missing email recipient")

    if (fileLocation.originatesFromThisUser) {
      if (email.to.length === 1) {
        return {
          event: "Sent to",
          short_event: "To",
          name: nameOrEmail(email.to[0]),
        }
      }

      // Either many recipients, or no recipient (everyone CC'd or BCC'd)
      return { event: "Sent to", short_event: "To", name: "many" }
    }
    return {
      event: "Received from",
      short_event: "From",
      name: nameOrEmail(email.from),
    }
  }
  if (fileLocation.storedInService === "recital") {
    return { event: "Uploaded to", short_event: "Upload to", name: "Recital" }
  }
  // Otherwise, it's saved to cloud storage
  if (!fileLocation.parentName) throw new Error("Error: missing parentName")

  return { event: "Saved in", short_event: "In", name: fileLocation.parentName }
}

export const openFileViaHiddenHTMLElement = (
  href: string,
  downloadFilename: string,
  openInNewTab: boolean,
): void => {
  // create a link but don't attach it to the DOM...it'll get garbage
  // collected when the variable goes out of scope
  const link = document.createElement("a")
  link.href = href
  if (openInNewTab) {
    link.target = "_blank"
    // firefox still needs a filename to open the blob in a new tab, otherwise
    // it generates its own with a ".dms" extension -- and isn't opened as eg. a PDF;
    // other browsers (tested Chrome and Safari), cannot have this set or it downloads
    // instead of opening
    if (isFirefox()) {
      link.download = downloadFilename
    }
  } else if (downloadFilename) {
    link.download = downloadFilename
  }

  link.click()
}

export const openInDesktopWord = async (fileUrl: string, fileExt: string): void => {
  // FIXME: This should probably use a path parser to do this properly
  const questionMarkAlreadyPresent = fileUrl.includes("?")
  const tokenSeparator = questionMarkAlreadyPresent ? "&" : "?"

  const expiringToken = await getExpiringAuthToken()

  if (!expiringToken) return // getExpiringAuthToken has already shown an error

  // if we click the element's link in the same frame, this will cancel
  // all outstanding AJAX requests; instead, open the link in a new hidden
  // iframe
  const iframe = document.createElement("iframe")
  iframe.name = "recital_hidden_iframe"
  iframe.height = "0"
  document.body.append(iframe)

  const link = document.createElement("a")
  // the file extension at the end is not a real HTTP parameter, but
  // MS Word needs it at the end to recognize a PDF document as a PDF
  link.href = `ms-word:nft|u|${fileUrl}${tokenSeparator}expiring_token=${expiringToken}&ext=.${fileExt}`
  link.target = "recital_hidden_iframe"
  link.click()

  // cleanup the iframe (has to be after the iframe loads the content)
  setTimeout(() => {
    iframe.remove()
  }, 1000)
}

// helper for getting the actual GraphQL actual query names, given a graphql
export const queryName = (gqlQuery: DocumentNode): string => {
  return gqlQuery.definitions[0].name.value
}
export const queryNames = (...gqlQueries: DocumentNode[]): string[] => {
  return gqlQueries.map((gqlQuery: DocumentNode) => queryName(gqlQuery))
}

export const downloadPdf = async (
  fileUrl: string,
  downloadFilename: string,
  downloadWithoutOpening: boolean,
): Promise<void> => {
  const blob = await downloadBlob(fileUrl)

  if (!blob) return // Error handling is within downloadBlob

  const href = URL.createObjectURL(blob)

  openFileViaHiddenHTMLElement(href, downloadFilename, !downloadWithoutOpening)

  // revoke the object only after we give the new tab its loading enough
  // time to start loading it (otherwise Firefox gets mad at us with an
  // "Access to 'blob:...' from script denied" error)
  setTimeout(() => {
    URL.revokeObjectURL(href)
  }, 10_000)
}

export const cleanTemporaryLocalStorageItems = (): void => {
  for (const key of ["confirmDelete", "confirmFileDelete"]) {
    localStorage.removeItem(key)
  }
}

export const logEventUsage = (logUsageData: UserEventData | undefined): void => {
  // log usage before we follow through to any links (otherwise we
  // might navigate away before the log event has a chance to go through)
  if (logUsageData) {
    try {
      logUsage(logUsageData)
    } catch (error) {
      // Log event if we hit an error
      captureMessage(`Could not log event ${error}`)
    }
  }
}

export const showErrorFromAxiosRequest = async (error: AxiosError): Promise<void> => {
  let responseData = error.response?.data

  // if the response is a JSON Blob (we get this when we request a blob
  // from Axios and get JSON back from the server), need to parse it
  if (responseData instanceof Blob && responseData.type === "application/json") {
    const jsonText = await responseData.text()
    responseData = JSON.parse(jsonText)
  }

  const errorMessage = responseData?.error || error?.response?.statusText
  showException(
    errorMessage ||
      "We seem to be having some technical issues.  Please try again later or message our help chat.",
    error,
  )
}

export const isUserLoggedIn = (): boolean => {
  const userInfoStore = useUserInfoStore()

  return !isEmpty(userInfoStore.data)
}

export const bestDisplayNameForUser = (): string => {
  const userInfoStore = useUserInfoStore()

  if (!isUserLoggedIn()) return ""

  return userInfoStore.data.name || userInfoStore.data.email?.replace(/@.+/, "")
}

export const isFileContentReadyForClauseSearch = (fileContent: FileRecord): boolean => {
  // for a user to be able to do clause searches on a file content, we need clause
  // indexing to complete AND grouping to complete (we don't show clause results
  // unless the file record has been grouped)
  return fileContent.automaticGroupingCompletedAt && fileContent.clauseIndexingCompletedAt
}

export const unifiedRedirectUrl = (integrationType: string, redirectUrl: string): string => {
  return `https://api.unified.to/unified/integration/auth/${unifiedWorkspaceId}/${integrationType}?&redirect=1&success_redirect=${redirectUrl}&failure_redirect=${redirectUrl}&state=${integrationType}`
}

export const integrationTypeIcon = (integrationType: string): Component => {
  // Add new icons in assets/sprites/svg
  const icons = {
    email: EmailIcon,
    one_drive: OneDriveIcon,
    google_drive: GoogleDriveIcon,
    sharepoint: SharepointIcon,
    box: BoxIcon,
    // FIXME: Can remove "unified" here after backend fix, Dec 2024 or later
    unified: BoxIcon,
  }

  const icon = icons[integrationType]

  if (!icon) throw new Error(`No icon available for ${integrationType}`)

  return icon
}
