export const isDev = import.meta.env.DEV
export const isCI = import.meta.env.VITE_CI
export const isProduction = import.meta.env.PROD && !isCI
export function isStorybook(): boolean {
  // @ts-expect-error -- TS doesn't know about storybook, that's okay
  return !!window.__STORYBOOK_PREVIEW__
}

let env

if (isDev) {
  env = "development"
} else if (isCI) {
  env = "test"
} else {
  env = "production"
}

export const environment = env

export const useHttps = isProduction || import.meta.env.VITE_USE_HTTPS_DEV

export const frontendUrl = `${useHttps ? "https" : "http"}://${
  import.meta.env.VITE_FRONTEND_DOMAIN
}`

export const apiUrl = `${useHttps ? "https" : "http"}://${import.meta.env.VITE_API_DOMAIN}`

export const graphqlUrl = `${apiUrl}/graphql`

// wss is the secure version of websockers, like HTTPS vs HTTP
export const websocketUrl = `${useHttps ? "wss" : "ws"}://${
  import.meta.env.VITE_API_DOMAIN
}/websocket/`

export const sentryDsn = import.meta.env.VITE_SENTRY_DSN

// Test token from Stytch for storybook etc
export const stytchPublicToken = import.meta.env.VITE_STYTCH_B2B_PUBLIC_TOKEN

export const segmentWriteKey = import.meta.env.VITE_SEGMENT_WRITE_KEY

export const oneSignalAppId = import.meta.env.VITE_ONE_SIGNAL_APP_ID

export const pushNotifSafariWebId = import.meta.env.VITE_PUSH_NOTIF_SAFARI_WEB_ID

export const configCatSdkKey = import.meta.env.VITE_CONFIGCAT_SDK_KEY

export const unifiedWorkspaceId = import.meta.env.VITE_UNIFIED_WORKSPACE_ID

// We want e.g. push notifications in dev mode, and production
export const disableExternalConnections =
  isCI || import.meta.env.VITE_ONE_SIGNAL_APP_ID === "DISABLED"

// For testing locally, don't check in `true`
// Also need to enable in backend config/initializers/ahoy.rb
export const USAGE_LOG_IN_DEV = false

export const basePageTitle = isDev ? "DEV Recital" : "Recital"
