<script setup lang="ts">
import { DefaultApolloClient } from "@vue/apollo-composable"
import { container as ModalContainer, config } from "jenesius-vue-modal"
import { provide } from "vue"
import { RouterView } from "vue-router"
import Toasts from "~/components/Notifications/Toasts.vue"
import { apolloClient } from "~/config/apollo"
import useCrisp from "~/config/crisp"
import useFeatureFlagOverrides from "~/config/featureFlagOverrides"
import { usePushNotifications } from "~/config/pushNotifications"
import { setupAnalytics } from "~/lib/logUsage"

useCrisp()

setupAnalytics()

useFeatureFlagOverrides()

usePushNotifications()

// Disable animations from the modal lib; we'll manage them ourselves
config({ animation: "none" })

// Apollo composition API must be installed in setup; options API setup is in main.ts
provide(DefaultApolloClient, apolloClient)
</script>

<template>
  <RouterView />
  <ModalContainer />
  <Toasts />
</template>
