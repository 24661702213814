import { cleanTemporaryLocalStorageItems } from "../../lib/componentUtils"

export default class CypressMockStytchClient {
  constructor() {
    this.session = {
      getSync: () => {
        // if the user has been mocked, consider user authenticated
        if (document.cookie.includes("stytch_session_jwt")) {
          return {
            mocked: true,
            // mock to an existing session so we don't tell the backend to
            // refresh the user from oauth data
            started_at: Date.now() - 60_000,
            last_accessed_at: new Date(),
          }
        }
        return false
      },

      revoke: async () => {
        cleanTemporaryLocalStorageItems()

        // revoke the mock session jwt cookie
        document.cookie = "stytch_session_jwt=; Max-Age=-99999999;"
      },
    }

    this.user = {
      getSync: () => {
        return {
          mocked: true,
        }
      },
    }

    this.mountlogin = () => {}
  }
}
