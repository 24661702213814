import axios from "~/lib/axios"
import { isFirefox } from "./componentUtils"
import { showException } from "./notify"

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener("error", reject)
    reader.addEventListener("load", () => {
      resolve((reader.result as string).split(",")[1])
    })
    reader.readAsDataURL(blob)
  })
}

export const downloadBlob = async (fileUrl: string): Promise<Blob | void> => {
  try {
    const response = await axios.get(fileUrl, {
      responseType: "blob",
    })

    let contentType = response.headers["content-type"]

    // Firefox prioritizes user preferences for how PDFs are handled over
    // the "download" attribute, and the default user preference opens
    // the PDF in Firefox's reader and does not download it.
    // See: https://bugzilla.mozilla.org/show_bug.cgi?id=1756980 for
    // lot's of discussion!
    // The best we can do to trigger a download is set the type to
    // "application/octet-stream" which will then download the PDF,
    // though still open it in a new tab after the download
    if (contentType === "application/pdf" && isFirefox()) {
      contentType = "application/octet-stream"
    }

    return new Blob([response.data], {
      type: contentType,
    })
  } catch (error) {
    showException(
      `There was a network issue downloading the file: ${error.message}. Please try again later or message our help chat.`,
      error,
    )
  }
}
