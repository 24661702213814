import { captureException, captureMessage } from "@sentry/vue"
import axios, { AxiosError } from "axios"
import { createNotifier, defineNotificationComponent } from "notiwind"
import type { NotificationSchema } from "notiwind/dist/types"
import { logUsage } from "~/lib/logUsage"
import router from "~/lib/router"

type CloseFunction = () => void

export { NotificationGroup } from "notiwind"

export interface ToastAction {
  onClick?: (e: MouseEvent, close: CloseFunction) => void
  text: string
  url?: string
}

export enum AlertType {
  WARNING = "warning",
  INFO = "info",
  ERROR = "error",
  SUCCESS = "success",
}

export interface NotificationPayload extends NotificationSchema {
  text: string
  title?: string
  type?: AlertType
  action?: ToastAction
  duration: number
}

interface NotificationOptions {
  title?: string
  type?: AlertType
  action?: ToastAction
  duration?: number
}

const notify = createNotifier<NotificationPayload>()
export const Notification = defineNotificationComponent<NotificationPayload>()

export const showToast = (message: string, options: NotificationOptions = {}): void => {
  // TODO: Most of the below logic can be centralized to the Toast action itself
  const { type = AlertType.SUCCESS, action, title } = options

  let { duration } = options
  if (type === AlertType.ERROR) {
    // We want errors to stick around long enough to be copied/screenshotted, but not so long that
    // they're permanent -- especially because some errors don't impact the user at all
    duration = 60_000
  } else if (type === AlertType.WARNING) {
    // Warnings are a bit less severe than errors, but users should still have time to read them
    duration = 15_000
  } else if (options.action) {
    // In order to give users time to act on action toasts
    // time is set to 5 seconds, non-action ones are set to 3
    // See "Move from soft deletion to hard deletion" design doc for more info
    duration ||= 5000
  } else {
    duration ||= 3000
  }

  if (action?.url) {
    action.onClick = () => {
      router.push(action.url)
    }
  }

  notify(
    {
      group: "toasts",
      text: message,
      action,
      type,
      duration,
      title,
    },
    duration,
  )

  if (type === AlertType.ERROR) {
    logUsage({ event: "error", errorMessage: message })
  }
}

export default showToast

export const showException = (error: string, exception?: Error, redirectionURL?: string): void => {
  showToast(`${error}`, { type: AlertType.ERROR })

  if (exception) {
    captureException(exception)
  } else {
    captureMessage(error)
  }

  if (redirectionURL) router.push(redirectionURL)
}

export const showAuthException = (exception: Error | AxiosError, error = undefined): void => {
  const isPaymentRequired = axios.isAxiosError(exception) && exception?.response?.status === 402

  if (isPaymentRequired) {
    // if we get a 402 Payment Required, the user's trial has expired
    showToast(
      "It looks like your trial use of Recital has expired. Need more time to try it out? Send us an email to request a trial extension.",

      {
        action: {
          text: "Request a trial extension",
          url: "mailto:support@recitalapp.com?subject=Trial%20extension%20request",
        },
        type: AlertType.INFO,
        duration: -1,
      },
    )
  } else {
    let msg = axios.isAxiosError(exception) ? exception.response?.data?.error : undefined
    msg ||= error
    msg ||=
      "We seem to be having some technical issues. Please try again later or message our help chat."

    showToast(msg, { type: AlertType.ERROR })
  }

  if (exception) captureException(exception)
  else captureMessage(error)
}
